import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import { useSigilContext } from '../context/sigilContext';


const StyledBox = styled(Box)(({ theme }) => ({
  paddingLeft: '25px',
  paddingRight: '25px',
  textAlign: 'center',
  textIndent: '25px',
  marginTop: '25px',
  [theme.breakpoints.up('md')]: {
    paddingLeft: '25%',
    paddingRight: '25%',
  },
}));


const Paragraph = ({children}) => (
  <StyledBox>
    <Typography
      variant="body1"
      sx={{ 
        textAlign: 'left',
        paddingX: '25px',
      }}
    >
     {children}
    </Typography>
</StyledBox>
)

const InfoPage = () => {

  const { i18n, setOpenImage } = useSigilContext();

  const translate = i18n.info;

  const handleOpenImage = () => {
    setOpenImage(true)
  };

  return (
    <Box sx={{ overflow: 'scroll', height: '100vh', paddingBottom: '130px', }}>
      <h1>{translate.title}</h1>
      {translate.texts.map( (text, index) => (
          <Paragraph key={`paragraph${index}`}>
            {text}
          </Paragraph>
        ))
      }
      <br />
      <span
        style={{
          color: "white",
          textDecoration: "underline",
          cursor: "pointer",
          fontWeight: 'bold',
        }}
        onClick={handleOpenImage}
      >
        {translate.imageName}
      </span>{" "}

      <p>— Peter J. Carroll</p>
    </Box> 
  );
}

export default InfoPage;