
export const es = {
  creation: {
    title: 'Crea tu sigilo',
    description: 'Escribe tu intención y con las letras restantes arma tu sigilo.',
    placeholderBox: 'Escribe tu intención aquí',
    createButton: 'Crear sigilo' 
  },
  drawing: {
    backButton: 'Volver',
    saveButton: 'Guardar',
    exportButton: 'Exportar'
  },
  mySigils: {
    title: 'Mis sigilos',
    emptyList: 'En este momento no tienes sigilos guardados.'
  },
  info: { 
    title: 'Sigilos',
    texts: [
      `"El mago puede necesitar de algo que es imposible de obtener para el mediante canales normales. Es a veces posible hacer que la coincidencia deseada suceda mediante la intervención directa de la voluntad, siempre y cuando esto no fuerce demasiado al universo. El mero acto de querer que algo suceda es generalmente muy poco efectivo, ya que la voluntad entra en dialogo con la mente. Esto diluye la habilidad magica en muchas maneras. El deseo se transforma en parte del ego, la mente entra en ansiedad debido al posible fracaso. La voluntad de no cumplir el deseo emerge entonces para calmar el miedo que produce el hipotetico fracaso. En un instante, el deseo original se ha transformado en una masa de ideas en conflicto. Muy freeuentemente, el resultado deseado solo emerge cuando ya nos olvidamos de el. Este ultimo hecho es central para los sigilos y la mayona de las formas de hechizos magicos. Los sigilos funcionan porque estimulan a la mente para actuar de forma inconsciente, sobrepasando a la mente.`,
      `"La operación con un sigil consta de tres partes: el sigil es construido, el sigil es perdido para la mente, el sigil es cargado. Al construir un sigil, el objetivo es construir un glifo del deseo, de forma estilizada, de modo que no sugiera inmediatamente que se refiere a ese deseo. No es necesario utilizar complejos sistemas simbólicos. La figura 2 muestra como los sigilos pueden construirse a partir de palabras, imagenes y sonidos. El objetivo de estos sigilos-ejemplo es meramente arbitrario y no recomendado`,
      `"Para perder al sigil con exito, tanto la forma del sigil como su deseo asociado deben ser limpiados de la conciencia normal. El mago actua contra cualquier manifestación de cualquiera de estos dos mediante la forzada colocación de la atención en otros asuntos. A veces, el sigil puede ser quemado, enterrado o tirado al oceano. Es tambien posible eliminar un hechizo de palabras mediante su constante repetición (como un mantra) ya que eventualmente elimina de la mente el deseo asociado. El sigil es cargado en momentos en los cuales la mente ha alcanzado su quintaesencia mediante el trance magico, o cuando una emoción fuerte paraliza su funcionamiento normal. Es en estos momentos cuando uno debe concentrarse en el sigil, ya sea en forma de una imagen mental, un mantra o un dibujo. Algunos de los momentos en los que un sigil puede ser cargado son estos: durante el trance magico, en el momento del orgasmo, en momentos de gran miedo, ira o verguenza; o incluso en momentos en los que la gran frustración o la decepción emerge. Alternativamente, cuando otro fuerte deseo distinto aparece y limpia de la mente al anterior, el deseo del sigil es olvidado y es posible cargar al sigil. Despues de haber tenido al sigil durante el mayor tiempo posible en la mente, es recomendable evanescerlo mediante la carcajada.`,
      `"Debes anotar en tu diario todo tu trabajo con sigilos, pero hazlo de manera que la deliberación consciente no interfiera con el deseo sigilizado."`
    ],
    imageName: 'Figura 2'
  },
  deleteModal: {
    title: 'Eliminar sigilo',
    description: '¿Estás seguro de eliminar tu sigilo?',
    cancelButton: 'Cancelar',
    deleteButton: 'Eliminar'
  },
  saveModal: {
    title: 'Guardar sigilo',
    placeholder: 'Nombre',
    saveButton: 'Guardar'
  },
  menu: [
    'Crea tu sigilo',
    'Ver mis sigilos',
    '¿Qué es un sigilo?'
  ],
  message: {
    save: {
      success: 'Guardaste tu sigilo',
      error: 'No es posible guardar la informacón'
    },
    export: {
      success: 'Exportaste tu sigilo',
      error: 'No se pudo exportar el sigilo'
    },
    delete: {
      success: 'Eliminaste tu sigilo',
    }
  },
  policy: {
    title: `Política de Privacidad`,
    texts: [
      {
        title: `Última actualización: 23/12/2024`,
        text: `Gracias por usar nuestra aplicación. La privacidad de nuestros usuarios es importante para nosotros. Esta política de privacidad explica cómo manejamos la información dentro de nuestra aplicación.`
      }, 
      {
        title: `Información que recopilamos`,
        text: `Nuestra aplicación no recopila, almacena ni comparte información personal o datos de los usuarios.`
      },
      {
        title: `Almacenamiento de dibujos`,
        text: `Los dibujos creados con nuestra aplicación se almacenan únicamente de forma local en el dispositivo del usuario. No accedemos, procesamos ni compartimos estos datos con terceros.`
      },
      {
        title: `Permisos`,
        text: `Nuestra aplicación no solicita permisos adicionales más allá de los necesarios para su funcionalidad básica.`
      },
      {
        title: `Servicios de terceros`,
        text: `Nuestra aplicación no utiliza servicios de terceros para recopilar datos ni realizar seguimiento.`
      },
      {
        title: `Cambios en esta política`,
        text: `Podemos actualizar esta política de privacidad ocasionalmente. Recomendamos revisar esta página para estar informado de cualquier cambio.`,
      },
      {
        title: `Contacto`,
        text: `Si tienes preguntas sobre esta política de privacidad, contáctanos a través de: mysticcodelab@gmail.com`
      }
    ]
  }
} 