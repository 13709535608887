import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import { useSigilContext } from '../context/sigilContext';

const StyledBox = styled(Box)(({ theme }) => ({
  paddingLeft: '25px',
  paddingRight: '25px',
  textAlign: 'center',
  textIndent: '25px',
  marginTop: '25px',
  [theme.breakpoints.up('md')]: {
    paddingLeft: '25%',
    paddingRight: '25%',
  },
}));

const Paragraph = ({children}) => (
  <StyledBox>
    <Typography
      variant="body1"
      sx={{ 
        textAlign: 'left',
        paddingX: '25px',
      }}
    >
      <p>
       <strong>
         {children.title}
       </strong>
      </p>
      
     {children.text}
    </Typography>
</StyledBox>
)


const Policy = () => {
  const { i18n } = useSigilContext();
  const translate = i18n.policy;


  return ( 
    <Box sx={{ overflow: 'scroll', height: '100vh', paddingBottom: '130px', }}>
      <h1>{translate.title}</h1>
      {translate.texts.map( (info, index) => (
          <Box sx={{   }}>
            <Paragraph key={`paragraph${index}`}>
              {info}
            </Paragraph>
          </Box>
        ))
      }
      <br />
    </Box> 
  );
}
 
export default Policy;